<template>
  <a href="#" class="category__service" @click.prevent="openModal">
    <span class="category__service-inner">
      {{ name }}
    </span>
  </a>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'CategoryService',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapMutations({
      openModal: 'popup/openModal',
    }),
  },
}
</script>
