<template>
  <section id="hero" class="category__hero">
    <LazyBackgroundWrapper
      :background-path="backgroundTexture"
      background-class="category__hero-background"
      :not-lazy="true"
    />
    <div class="category__hero-wrapper">
      <div class="container">
        <h1 class="category__hero-title">
          <slot />
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CategoryHero',
  props: {
    backgroundTexture: {
      type: String,
      default: 'paper-texture',
    },
  },
}
</script>
