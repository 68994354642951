<template>
  <section>
    <ul
      v-if="articles.length > 0"
      class="
        category__article-list
        h-padding-left-1-tablet-down h-padding-left-0-small-down
      "
    >
      <li v-for="(article, index) in articles" :key="index">
        <nuxt-link :to="article.path" class="category__article">
          {{ article.component.data().title }}
        </nuxt-link>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    articles: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
</script>
