<template>
  <section class="category__services-wrapper">
    <div class="container">
      <div class="category__services">
        <category-service
          v-for="(service, index) in services"
          :key="index"
          :name="service.name"
        />
      </div>
      <Modal />
    </div>
  </section>
</template>

<script>
import CategoryService from '~/components/CategoryService.vue'

export default {
  name: 'CategoryServices',
  components: {
    CategoryService,
  },
  props: {
    services: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
</script>
