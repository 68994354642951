<template>
  <!-- TODO: Make this a separate div for lazy loading -->
  <div
    :style="`background-image: url(${cover})`"
    class="call-to-action__wrapper"
  >
    <div class="container">
      <article
        class="
          call-to-action
          column
          is-9 is-offset-2 is-12-small
          no-offset-small
        "
      >
        <div>
          <!-- eslint-disable -->
          <h2 class="call-to-action__title" v-html="title">
            {{ title }}
          </h2>
          <!-- eslint-enable -->
          <div
            class="
              call-to-action__content
              text
              -size-large
              -color-white
              -font-weight-700
            "
          >
            {{ content }}
          </div>
        </div>
        <div class="call-to-action__buttons">
          <nuxt-link :to="button.url" class="button">
            <span class="button__inner">{{ button.title }}</span>
          </nuxt-link>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CallToAction',
  props: {
    cover: {
      type: String,
      required: false,
      default: require('~/assets/images/paper-texture.jpg'),
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: false,
      default: () => '',
    },
    button: {
      type: Object,
      required: false,
      default: () => {
        return {
          title: 'Button',
          url: 'https://graffino.com',
        }
      },
    },
  },
}
</script>
