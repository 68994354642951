<template>
  <div>
    <slot name="hero" />
    <slot name="intro" />
    <slot name="services" />
    <slot name="articles" />
    <slot name="callToAction" />
  </div>
</template>
<script>
export default {
  name: 'Category',
}
</script>
